import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Img from 'gatsby-image'
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
// import { Splide, SplideSlide } from '@splidejs/react-splide'
import Popup from "../components/popup"
import {Carousel} from 'react-bootstrap'


const IndexPage = ({ data }) => (
  <Layout>
    <SEO title={data.strapiHome.meta_titel + " | Home"} description={data.strapiHome.meta_omschrijving} lang="nl" />
    <div className="container">
        <div className="row no-gutters cta-wiezijnwe">
          <div className="col-lg-6 col-xl-4 d-flex flex-column justify-content-center bg-wervel">
            <div className="cta-wiezijnwe-tekst cta-wiezijnwe-tekst--home">
              <h1>{data.strapiHome.titel}</h1>
              <ReactMarkdown source={data.strapiHome.tekst} />
            </div>
          </div>
          <div className="col-lg-6 col-xl-8 cta-wiezijnwe-afb">
            <Carousel>
              {data.strapiHome.afbeeldingen.sort((a, b) => (a.name > b.name) ? 1 : -1).map(document =>(
                <Carousel.Item key={document.id}>
                  {document.width > document.height &&
                    <img
                      className="d-block w-100"
                      src={document.url}
                      alt={document.alternativeText}
                    />
                  }
                  {document.width < document.height &&
                    <img
                      className="d-block carouselItemPortrait carouselItemPortrait-home"
                      src={document.url}
                      alt={document.alternativeText}
                    />
                  }
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="specialisaties specialisaties-home">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mb-2"><h2>Onze specialisaties</h2></div>
            {data.allStrapiBehandeling.edges.map((document, index) =>(
              <div className="col-lg-4 mb-3 mb-lg-0 specialisatie-col" key={document.node.id}>
                <div className={"specialisatie d-md-flex d-lg-block specialisatie-" + index}>
                  <div className="specialisatie-afb mr-md-3 mr-lg-0">
                    <Link to={`/specialisaties/${document.node.titel.replace(/\s/g, "").toLowerCase()}`}>
                      {document.node.afbeeldingen.sort((a, b) => (a.name > b.name) ? 1 : -1).map((afb, i) =>(
                        <div key={afb.id}>
                          {i < 1 &&
                            <img src={afb.url} alt={afb.alternativeText} className="mb-3 mb-md-0 mb-lg-3" />
                          }
                        </div>
                      ))}
                    </Link>
                  </div>
                  <div className="specialisatie-intro text-center text-md-left text-lg-center">
                    <h3>{document.node.titel}</h3>
                    <ReactMarkdown source={document.node.intro} />
                    <Link to={`/specialisaties/${document.node.titel.replace(/\s/g, "").toLowerCase()}`}>
                      <span className="btn btn-outline-primary">Lees meer</span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
            <div className="col-12 text-center"><Link to="/specialisaties"><span className="btn btn-primary mt-3">Ontdek alle specialisaties</span></Link></div>
          </div>
        </div>
      </div>
      <Popup strapiHome={data.strapiHome} />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query IndexQuery {
    strapiHome {
      titel
      tekst
      popup
      popup_titel
      meta_titel
      meta_omschrijving
      afbeeldingen {
        alternativeText
        id
        width
        height
        name
        url
      }
    }
    allStrapiBehandeling(limit: 3) {
      edges {
        node {
          id
          titel
          intro
          afbeeldingen {
            alternativeText
            id
            name
            url
          }
        }
      }
    }
  }
`