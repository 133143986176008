import React, {useState} from "react"
import {Button, Modal} from 'react-bootstrap'
import ReactMarkdown from "react-markdown"

function Popup({ strapiHome }) {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button variant="primary" onClick={handleShow} className="d-none" id="js-launchModal">
          Launch modal
        </Button>
  
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{strapiHome.popup_titel}</Modal.Title>
          </Modal.Header>
          <Modal.Body><ReactMarkdown source={strapiHome.popup} /></Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Sluiten
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default Popup